<template>
  <div class="drt-container">
    <drt-map v-if="isRegistered" class="map" :has-feature-elgin="hasFeatureElgin" />
    <drt-form v-if="isRegistered" />
    <drt-register v-if="!isRegistered" />
  </div>
</template>

<script>
import { status } from 'api/drt'
import store from 'store'
import drtMap from './map'
import drtForm from './form'
import drtRegister from './register'
import authentication from 'mixins/authentication'
import { handleErrors } from 'utils/utils'

export default {
  name: 'Shotl',
  components: { drtMap, drtForm, drtRegister },
  mixins: [authentication],
  data () {
    return {
      isRegistered: false
    }
  },
  computed: {
    hasFeatureElgin () {
      return this.hasPermission('feature.mfd1.579.elginmap')
    }
  },
  beforeMount () {
    this.$store.dispatch('drt/reset')
    if (this.$route.query.smartsearch) {
      if (this.$route?.params?.origin) {
        this.$store.dispatch('drt/setOrigin', this.$route.params.origin)
      }
      if (this.$route?.params?.destination) {
        this.$store.dispatch('drt/setDestination', this.$route.params.destination)
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const { username } = store.getters['user']
      const { data: { registered_user } } = await status('shotl', username)
      next(vm => { vm.isRegistered = registered_user })
    } catch (err) {
      handleErrors(err)
      next(from)
    }
  }
}
</script>

<style lang="stylus" scoped>
.drt-container
  position relative
  height calc(100vh - 64px)
  overflow auto
  .map
   @media (max-width 768px)
    height calc(60vh)
</style>
