import http from './http'

export function register (params) {
  http.post('/drt/register', params)
}

export function availability (params) {
  return http.get('/search/drt/availability', { params })
}

export function summary (token) {
  return http.get(`/search/drt/summary/${token}`)
}

export function status (supplier, username) {
  return http.get(`/drt/${supplier}/status/${username}`)
}
