<template>
  <q-page class="layout-padding">
    <div v-if="summary" class="row q-col-gutter-xs">
      <div class="col-xs-12">
        <q-card class="bg-white">
          <q-card-section v-if="!$q.platform.is.mobile" class="bg-primary text-white">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <div class="row">
              <div class="col-12 column">
                <div class="col">
                  <sub>{{ $t('pick_up_location') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.pickup_from }}
                  </h6>
                </div>
                <div class="col">
                  <sub>{{ $t('drop_off_location') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.dropoff_at }}
                  </h6>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('time.pick_up') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.pickup_time | date }}
                </h6>
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('drop_off') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.dropoff_time | date }}
                </h6>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="q-mt-md">
              {{ $t('other_travel_information') }}
            </h6>
            <div class="col-12 col-sm-6">
              <sub>{{ $t('number_of.passengers') }}</sub>
              <h6 class="text-grey-9">
                {{ summary.passengers.adults + summary.passengers.children }}
              </h6>
            </div>
            <q-separator class="hr" />
            <div v-for="(item, i) in summary.order_items" :key="i" class="order-item">
              <div class="description">
                {{ item.description }}
              </div>
              <div class="grid">
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('net_amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.net_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('tax.amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.tax_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('total_cost') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.total.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="row text-bold">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span class="col-3 text-grey-9 text-right">{{ summary.total_amount.text }}</span>
            </h6>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-xs-12">
        <m-attach-to-booking-request
          :suggested="summary.suggested_journey_name"
          :username="traveller.value"
          :journey="journey"
          :show-travel-type="organisation && organisation.attributes.travel_policy.drt.enabled"
          @change="(val) => $store.dispatch('ondemand/journey', val)"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
          @travelTypeChange="(val) => travelType = val"
        />
      </div>
      <div v-if="!summary.total_amount.amount && !shouldBeFree" class="col-sm-12">
        <m-banner
          :title="$t('important')"
          :message="$t('drt.summary.pay_on_vehicle')"
          color="warning"
        />
      </div>
      <payment-buttons
        :total-amount="summary.total_amount.amount"
        :requester="requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="summary.booking_token"
        :travel-type="(organisation && organisation.attributes.travel_policy.drt.enabled) || !organisation ? travelType : 'Private'"
        :supplier-handled="!summary.total_amount.amount"
      />
      <sub
        class="col-12 text-center text-faded q-mt-sm"
        v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
      />
    </div>
  </q-page>
</template>

<script>
import { availability, summary } from 'api/drt'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import paymentButtons from 'pages/payments/payment-method-buttons'

import { MAttachToBookingRequest, MBanner } from 'components/'

export default {
  components: {
    paymentButtons,
    MAttachToBookingRequest,
    MBanner
  },
  filters: {
    date: value => date.toMediumDateTime(value)
  },
  mixins: [authentication],
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      traveller: 'userLookup',
      requester: 'userLookup',
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation'
    }),
    shouldBeFree () {
      const { passengers } = this.summary
      return passengers.adults === passengers.free_bus_pass
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner

    loading.start({
      message: i18n.t('loading.checking.drt'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    const params = store.getters['drt/params']

    try {
      const { data: { ride_token, passengers } } = await availability(params)
      if (!ride_token) return next(from)
      const { data } = await summary(ride_token)
      next(vm => {
        vm.summary = { ...data, passengers }
      })
      loading.stop()
    } catch (err) {
      handleErrors(err)
      next(from)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px!important
  margin auto
span, .hr
  margin-top 16px
button
  width 100%
.summary-main
  padding 20px

.q-card
  @media (max-width 768px)
    margin 0

.grid
  display grid
  grid-template-columns repeat(auto-fill, 33%);
  font-weight 400
  align-items baseline

.order-item
  margin-top 16px

.grid > div
  padding 10px 0

.grid > div:nth-child(2)
  text-align center

.grid > div:nth-child(3)
  text-align right

.description
  font-weight 700

h6.text-bold
  padding 0 10px
</style>
