<template>
  <q-page class="bg-white">
    <div class="code flex items-center justify-center" />
    <q-card square class="bg-white">
      <q-card-section v-if="$q.platform.is.desktop" class="bg-primary">
        Dial M for Moray
      </q-card-section>
      <div class="drt-form-text">
        {{ $t('drt.register.blurb') }}
      </div>
      <div class="drt-form-text">
        {{ $t('drt.register.instructions') }}
      </div>
      <form>
        <label>
          {{ $t('name.forename') }}
          <input id="forename" v-model="params.first_name">
        </label>

        <label>
          {{ $t('name.surname') }}
          <input id="surname" v-model="params.last_name">
        </label>

        <label>
          {{ $t('email') }}
          <input id="email" v-model="params.email" disabled>
        </label>

        <label>
          {{ $t('phone') }}
          <div class="phone">
            <q-select
              v-model="dialling_code"
              :display-value="`+${dialling_code}`"
              :options="callingCodeSelect"
              dense
              filter
              emit-value
              map-options
              :borderless="$q.platform.is.mobile"
              :disable="disablePhone"
            />
            <input id="phone" v-model="params.phone_number" :disabled="disablePhone">
          </div>
        </label>

        <date-field
          class="date-field"
          :value="params.dob"
          :label="$t('date.birth')"
          :borderless="$q.platform.is.mobile"
          @input="setDate"
        />

        <m-location-search
          class="shotl-form"
          style="margin: 5px 0 -3px;"
          :label="$t('home_address')"
          :placeholder="$t('search_by_postcode')"
          :value="address"
          :show-icon="false"
          stack-label
          @selected="setAddress"
        />

        <div class="inner-grid">
          <input id="mobility-assistance" v-model="params.cant_walk" type="checkbox">
          <label class="checkbox-label" for="mobility-assistance">{{ $t('drt.do_you_require_assistance') }}</label>
          <label v-if="params.cant_walk" for="type-of-assistance" class="mb-type-label">Mobility Assistance</label>
          <textarea
            v-if="params.cant_walk"
            id="type-of-assistance"
            v-model="type_of_assistance"
            :placeholder="$t('drt.type_of_assistance_details')"
          />
        </div>

        <div class="inner-grid">
          <input id="wheelchair" v-model="params.is_wheelchair_user" type="checkbox">
          <label class="checkbox-label" for="wheelchair">{{ $t('drt.do_you_require_space') }}</label>
          <label v-if="params.is_wheelchair_user" class="mb-type-label" for="mobility-type">{{ $t('drt.mobility_type') }}</label>
          <select
            v-if="params.is_wheelchair_user"
            id="mobility-type"
            v-model="type_of_wheelchair"
          >
            <option disabled value="">
              {{ $t('please_select_one') }}
            </option>
            <option
              v-for="item in mobility_aids"
              :key="item.name"
              :value="item.name"
            >
              {{ item.description }}
            </option>
          </select>
        </div>
        <div class="consent" v-html="$t('drt.register.consent')" />

        <div class="tnc">
          <input id="tnc" v-model="params.terms_conditions_accepted" type="checkbox">
          <label class="checkbox-label" for="tnc">{{ $t('terms_of_carriage') }}</label>
        </div>
        <button id="submit" class="bg-primary" @click="submit">
          {{ $t('submit') }}
        </button>
      </form>
    </q-card>
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import worldCountries from 'world-countries/dist/countries.json'
import _ from 'lodash'
import { mobilityAids } from 'api/mobility'
import { register } from 'api/drt'
import { dateField, MLocationSearch } from 'components/'
import { Dialog } from 'quasar'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import { handleErrors } from 'utils/utils'

export default {
  name: 'DRTRegistrationForm',
  components: { dateField, MLocationSearch },
  data () {
    return {
      mobility_aids: [],
      address: null,
      date: null,
      dialling_code: 44,
      params: {
        supplier: 'shotl',
        first_name: null,
        last_name: null,
        dob: null,
        email: null,
        phone_number: null,
        cant_walk: false,
        is_wheelchair_user: false,
        terms_conditions_accepted: false,
        full_address: null
      },
      type_of_assistance: '',
      type_of_wheelchair: '',
      disablePhone: false
    }
  },
  computed: {
    callingCodeSelect () {
      const countryData = _.orderBy(worldCountries, 'name.common')
      const callingCodeSelect = []

      countryData.forEach((c) => {
        if (c.idd.root) {
          c.idd.suffixes.forEach(code => {
            const trimmedCode = code.replace(/X/g, '')
            callingCodeSelect.push({
              label: `${c.idd.root}${trimmedCode} - ${c.name.common}`,
              value: c.idd.root.slice(1) + trimmedCode
            })
          })
        }
      })

      return callingCodeSelect
    }
  },
  validations: {
    params: {
      first_name: { required },
      last_name: { required },
      dob: { required },
      full_address: { required },
      email: { required, email },
      phone_number: { required },
      terms_conditions_accepted: { required }
    },
    type_of_wheelchair: { required: requiredIf('params.is_wheelchair_user') }
  },
  async beforeMount () {
    const { meta, email } = this.$store.getters.user
    const { data } = await mobilityAids()
    this.mobility_aids = data
    this.params.first_name = meta.first_name
    this.params.last_name = meta.last_name
    this.params.email = email
    if (meta.mobile) {
      this.params.phone_number = meta.mobile.slice(1)
      this.disablePhone = true
    }
  },
  methods: {
    setDate (val) {
      const civil = date.toCivilDate(val)
      this.params.dob = civil
    },
    setAddress (val) {
      this.address = val
      this.params.full_address = val.label || val.text
    },
    async submit (event) {
      event.preventDefault()
      if (this.$v.$invalid) return

      const shapedParams = { ...this.params }

      shapedParams.phone_number = `+${this.dialling_code}${this.params.phone_number}`
      if (this.params.cant_walk) shapedParams.type_of_assistance = this.type_of_assistance
      if (this.params.is_wheelchair_user) shapedParams.type_of_wheelchair = this.type_of_wheelchair

      try {
        await register(shapedParams)
        this.$router.push('/')
        Dialog.create({
          noBackdropDismiss: true,
          noEscDismiss: true,
          title: 'Dial-M Registration Successful',
          message: 'You have successfully registered to Dial-M.',
          buttons: ['Ok']
        })
      } catch (err) {
        handleErrors(err)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../styles/ondemand.styl'

.code
  background-image url('~assets/backgrounds/dialM.jpg')

.q-page
  display flex
  flex-direction column
  align-items center

.q-card
  margin 0
  @media (min-width 700px)
    margin-top -56px

.drt-form-text
  max-width 600px
  padding 20px 20px 0px

form
  max-width 600px
  display grid
  grid-template-columns 1fr 1fr
  grid-column-gap 15px
  grid-row-gap 5px
  grid-template-rows auto
  grid-template-areas ". ." \
    "forename-input surname-input" \
    ". ." \
    "email-input phone-input" \
    ". ." \
    ". ." \
    "wheelchair mobility-assistance" \
    "consent consent" \
    "tnc tnc" \
    "privacy privacy" \
    "submit submit"
  padding 20px
  @media (max-width 700px)
    display flex
    flex-direction column
    width 100vw

.inner-grid
  display grid
  grid-template-columns 25px auto
  grid-template-rows auto
  grid-template-areas ". ." \ "label label" \ "type type"

.tnc
  grid-area tnc
  display flex

.privacy
  grid-area privacy
  display flex

#forename
  grid-area forename-input

#surname
  grid-area surname-input

#email
  grid-area email-input
  margin-top 5px

#submit
  grid-area submit
  border none
  border-radius 25px
  padding 8px 16px

#mobility-type, #type-of-assistance
  grid-area type

.consent
  grid-area consent
  font-size 80%

input
  background-color rgba(0, 0, 0, 0)
  @media (min-width 700px)
    padding 5px 0
    border-bottom solid rgba(0,0,0,0.2) 1px
    transition border-bottom .36s
    transition-timing-function cubic-bezier(0.4, 0, 0.2, 1)
    &:hover
      border-bottom solid rgba(0,0,0,0.54) 1px
    &:focus
      border-bottom solid convert(brandColor)
  &[type="checkbox"]
    appearance checkbox
    margin 5px

.phone
  display flex
  flex-direction row
  grid-area phone-input
  @media (max-width 700px)
    margin-bottom -7px
    margin-top -9px

textarea
  padding 5px
  border solid rgba(0,0,0,0.2) 1px

label
  display flex
  flex-direction column
  font-size 75%
  letter-spacing 0.00937em
  margin 5px 0
  color rgba(0,0,0,0.6)
  &.q-select
    font-size 100%
    margin 0
    padding 0
    border none
  @media (max-width 700px)
    border solid rgba(0,0,0,0.2) 1px
    border-radius 25px
    padding 8px 16px
    &.q-select
      font-size 120%

select
  appearance none
  color rgba(0,0,0,0.6)
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%)
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat
  background-color white
  padding 8px 16px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px

.checkbox-label
  border none
  padding 0

.mb-type-label
  grid-area label
  border none
  padding 0

.date-field
  padding-top 0
  padding-bottom 2px
  font-size 100%
</style>
