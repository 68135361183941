<template>
  <div>
    <q-card class="bg-none shotl-form">
      <div v-if="tab === 'form'" class="choose-route">
        <button
          class="set-origin drt-button"
          :class="selectionMode"
          @click="() => $store.dispatch('drt/setSelectionMode', 'origin')"
        >
          <q-icon
            name="fas fa-location"
            size="24px"
          />
        </button>
        <m-location-search
          ref="originField"
          no-saved
          :placeholder="$t('pick_up') + ': ' + $t('click_on_map_or_type')"
          :value="originField"
          :show-icon="false"
          :input-style="{'font-size': '110%'}"
          style="margin-bottom:-4px; margin-top: 4px"
          @selected="val => originField = val"
          @open="() => tall = true"
          @close="() => tall = false"
        />
        <button class="swap" @click="swapLocations">
          <q-icon
            name="fas fa-sort-alt"
            size="24px"
          />
        </button>
        <button
          class="set-destination drt-button"
          :class="selectionMode"
          @click="() => $store.dispatch('drt/setSelectionMode', 'destination')"
        >
          <q-icon
            name="fas fa-map-marker"
            size="24px"
          />
        </button>
        <m-location-search
          ref="destinationField"
          no-saved
          :placeholder="$t('drop_off') + ': ' + $t('click_on_map_or_type')"
          :value="destinationField"
          :show-icon="false"
          :input-style="{'font-size': '110%'}"
          style="margin-bottom:-4px; margin-top: 4px"
          @selected="val => destinationField = val"
          @open="() => tall = true"
          @close="() => tall = false"
        />
      </div>
      <leave-now
        v-if="tab === 'form'"
        :departing="time"
        :show-transport-prefs="false"
        :show-passengers="{ adults, children }"
        :disabled="disabled"
        :date-options="dateOptions"
        :time-options="timeOptions"
        :railcard-options="concessionOptions"
        @passengers="confirmRoute"
        @concessions="handleConcessions"
        @depart="setTime"
        @submit="submit"
      >
        <div id="info">
          <i18n path="drt.check_our">
            <strong @click="tab='conditions'">{{ $t('drt.operating_hours') }}</strong>
          </i18n>
        </div>
      </leave-now>

      <div v-if="tab === 'conditions'">
        <button class="back-button" @click="tab='form'">
          <q-icon name="arrow_back" size="24px" />
        </button>
        <table class="timetable">
          <tr><td>{{ $t('days_of_week')[0] }}</td><td>09.15 - 14.30</td><td>16.30 - 18.00</td></tr>
          <tr><td>{{ $t('days_of_week')[1] }}</td><td>09.15 - 14.30</td><td>16.30 - 18.00</td></tr>
          <tr><td>{{ $t('days_of_week')[2] }}</td><td>09.15 - 14.30</td><td>16.30 - 18.00</td></tr>
          <tr><td>{{ $t('days_of_week')[3] }}</td><td>09.15 - 14.30</td><td>16.30 - 18.00</td></tr>
          <tr><td>{{ $t('days_of_week')[4] }}</td><td>09.15 - 14.30</td><td>16.30 - 18.00</td></tr>
          <tr><td>{{ $t('days_of_week')[5] }}</td><td>{{ $t('drt.no_service') }}</td></tr>
          <tr><td>{{ $t('days_of_week')[6] }}</td><td>{{ $t('drt.no_service') }}</td></tr>
        </table>
        <button class="back-button bg-primary full-width q-mt-md" @click="tab='form'">
          OK
        </button>
      </div>
    </q-card>
  </div>
</template>

<script>
import { MLocationSearch, LeaveNow } from 'components/'
import date from 'utils/date-time'
import { mapGetters } from 'vuex'
const { newDate, addToDate, toCivilDate, toHugeDate, toCivilTime } = date

export default {
  name: 'DRTForm',
  components: { MLocationSearch, LeaveNow },
  data () {
    return {
      tall: false,
      tab: 'form',
      concessionOptions: [{ name: 'National Entitlement Card', code: 'NEC' }]
    }
  },
  computed: {
    ...mapGetters({
      origin: 'drt/origin',
      destination: 'drt/destination',
      time: 'drt/time'
    }),
    originField: {
      get () {
        return this.$store.getters['drt/originField']
      },
      async set (val) {
        this.$store.dispatch('drt/setOrigin', val)
      }
    },
    destinationField: {
      get () {
        return this.$store.getters['drt/destinationField']
      },
      set (val) {
        this.$store.dispatch('drt/setDestination', val)
      }
    },
    adults: {
      get () {
        return this.$store.getters['drt/adults']
      },
      set (num) {
        this.$store.dispatch('drt/setPassengers', { type: 'adults', num })
      }
    },
    children: {
      get () {
        return this.$store.getters['drt/children']
      },
      set (num) {
        this.$store.dispatch('drt/setPassengers', { type: 'children', num })
      }
    },
    concession: {
      get () {
        return this.$store.getters['drt/concession']
      },
      set (num) {
        this.$store.dispatch('drt/setPassengers', { type: 'concession', num })
      }
    },
    wheelchairs: {
      get () {
        return this.$store.getters['drt/wheelchairs']
      },
      set (num) {
        this.$store.dispatch('drt/setPassengers', { type: 'wheelchairs', num })
      }
    },
    selectionMode () {
      return this.$store.getters['drt/selectionMode']
    },
    disabled () {
      return !this.origin || !this.destination
    }
  },
  mounted () {
    if (process.env.NODE_ENV !== 'test') { this.$refs.originField.$refs.input.focus() }
    // Default "Now" to 15 minutes from now
    this.setTime(toCivilTime(addToDate(date.now(), { minutes: 15 })))
  },
  methods: {
    dateOptions (value) {
      const day = toHugeDate(value.replace(/\//g, '-'))

      if (day.startsWith('Saturday')) return false
      if (day.startsWith('Sunday')) return false

      return value.replace(/-/g, '/') >= toCivilDate(newDate()).replace(/-/g, '/')
    },
    timeOptions (hour, minutes) {
      // 09.15-14.30 || 16.30-18.30
      if (hour < 9 || hour === 15 || hour > 18) return false
      if (minutes !== null) {
        if (hour === 9 && minutes < 15) return false
        if (hour === 14 && minutes > 30) return false
        if (hour === 16 && minutes < 30) return false
        if (hour === 18 && minutes > 0) return false
      }
      return true
    },
    isValidTime (date) {
      const [hour, minutes] = toCivilTime(date).split(':')

      return this.dateOptions(toCivilDate(date)) && this.timeOptions(Number(hour), Number(minutes))
    },
    submit () {
      if (!this.isValidTime(this.time)) return this.$q.notify({ message: this.$t('drt.validation.time'), color: 'negative' })
      else this.$router.push({ name: 'ondemand-drt-summary' })
    },
    setTime (time) {
      this.$store.dispatch('drt/setTime', time)
    },
    confirmRoute (val) {
      const { adults, children } = val
      this.adults = adults
      this.children = children
    },
    handleConcessions (cards) {
      this.concession = cards.filter(card => card.code).length
    },
    swapLocations () {
      const oldOrigin = this.$store.getters['drt/originField']
      const oldDestination = this.$store.getters['drt/destinationField']

      this.destinationField = { value: oldOrigin.place_id }
      this.originField = { value: oldDestination.place_id }
    }
  }
}
</script>

<style lang="stylus" scoped>
.shotl-form
  bottom 0
  right 35%
  width 30vw
  position absolute
  overflow hidden
  z-index 1
  padding 10px 10px 0
  @media (max-width: 768px) {
    width 100vw
    right 0
    left 0
    margin 0
  }

.q-field--with-bottom
  padding-bottom 0

.choose-route
  display grid
  grid-template-columns 36px auto 30px
  grid-column-gap 6px
  grid-template-rows auto
  align-items center

.swap
  grid-column-start 3
  grid-row-start 1
  grid-row-end 3
  color convert(brandColor)
  background white
  border none

.drt-button
  background white
  padding 6px
  border none
  display flex
  flex-direction column
  justify-content center
  align-items center

.set-origin
  color blue
  &.origin
    background tint(convert(brandColor), 80%)
    box-shadow inset 0px 0px 5px convert(brandColor)
    outline none

.set-destination
  color orange
  &.destination
    background tint(convert(brandColor), 80%)
    box-shadow inset 0px 0px 5px convert(brandColor)
    outline none

.confirm
  background convert(brandColor)
  color white
  font-size 20px
  width 100%
  grid-column-start 1
  grid-column-end 4
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px

.timetable
  width 100%

#info
  text-align right
  border none
  background white
  width 100%
  margin-bottom 10px
  padding-right 10px
  color black
  strong
    color convert(brandColor)

.back-button
  border none
  background white
  margin-bottom 10px
  border-radius 5px
</style>
