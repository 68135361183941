<template>
  <GmapMap
    ref="map"
    :options="mapConfig"
    :center="center"
    :zoom="15"
    class="route-map"
    @click="handleMapClick"
  >
    <GmapMarker
      v-if="origin"
      ref="originMarker"
      :position="origin"
      class="marker"
      :icon="{
        url: require(`assets/markers/map-marker@0.5x.png`)
      }"
    />
    <GmapMarker
      v-if="destination"
      ref="destinationMarker"
      :position="destination"
      class="marker"
      :icon="{
        url: require(`assets/markers/map-marker@0.5x.png`)
      }"
    />
    <GmapPolyline
      v-if="routeInfo.distance.kilometres"
      ref="line"
      :key="routeInfo.distance.kilometres"
      :options="{ strokeColor: '#218AE5', strokeWeight: 4 }"
    />
  </GmapMap>
</template>

<script>
import mapConfig from 'pages/ondemand/ride-hailing/map-config'
import { gmapApi } from 'gmap-vue'
import { mapGetters } from 'vuex'
import { routeInfo } from 'api/ride-hailing'
import geoJSON from './speyside_and_keith1_together.json'
import featureGeoJSON from './speyside_and_elgin.json'

const environment = process.env.NODE_ENV

export default {
  name: 'DRTMap',
  props: {
    hasFeatureElgin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mapConfig,
      center: {
        lat: 57.32171214323943,
        lng: -3.5834387018360303
      },
      icons: [],
      routeInfo: {
        distance: {
          kilometres: 0
        }
      }
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      origin: 'drt/origin',
      destination: 'drt/destination',
      selectionMode: 'drt/selectionMode',
      partner: 'partner'
    })
  },
  watch: {
    origin: {
      immediate: true,
      async handler (val) {
        if (!val) {
          this.routeInfo.distance.kilometres = 0
          return
        }
        if (this.destination) {
          const { data } = await routeInfo([val, this.destination])
          this.routeInfo = data
          this.setBounds()
        }
      }
    },
    destination: {
      immediate: true,
      async handler (val) {
        if (!val) {
          this.routeInfo.distance.kilometres = 0
          return
        }
        if (this.origin) {
          const { data } = await routeInfo([this.origin, val])
          this.routeInfo = data
          this.setBounds()
        }
      }
    },
    routeInfo: {
      immediate: true,
      async handler (val) {
        if (environment === 'test') return
        if (!val.distance.kilometres) return
        const polyline = val.points
        await this.$nextTick()
        const line = await this.$refs.line.$polylinePromise
        let step = 0
        let point = 0
        let numSteps = 1
        let timePerStep = 1

        line.setPath([])

        if (polyline.length < 2) return

        let departure = new this.google.maps.LatLng(polyline[step].lat, polyline[step].lng)
        let arrival = new this.google.maps.LatLng(polyline[step + 1].lat, polyline[step + 1].lng)

        function drawLine () {
          step += 1
          if (step > numSteps) {
            clearInterval(interval)
            if (point + 1 < polyline.length) {
              step = 0
              departure = new this.google.maps.LatLng(polyline[point].lat, polyline[point].lng)
              arrival = new this.google.maps.LatLng(polyline[point + 1].lat, polyline[point + 1].lng)
              point += 1
              interval = setInterval(drawLine, timePerStep)
            }
          } else {
            let areWeThereYet = this.google.maps.geometry.spherical.interpolate(departure, arrival, step / numSteps)
            line.setPath([...line.getPath().getArray(), departure, areWeThereYet])
          }
        }

        let interval = setInterval(drawLine, timePerStep)
      }
    }
  },
  mounted () {
    if (environment === 'test') return
    this.$nextTick(() => {
      this.$refs.map.$mapPromise.then(() => {
        const useGeoJSON = this.hasFeatureElgin ? featureGeoJSON : geoJSON
        const mapData = this.$refs.map.$mapObject.data
        mapData.addGeoJson(useGeoJSON)
        mapData.setStyle({ fillColor: this.partner.hex, strokeColor: this.partner.hex })

        const bounds = new this.google.maps.LatLngBounds()
        mapData.forEach(function (feature) {
          feature.getGeometry().forEachLatLng(function (latlng) {
            bounds.extend(latlng)
          })
        })
        const padding = window.innerWidth > 768
          ? { bottom: 300, right: 300, top: 100 }
          : { top: 0, bottom: 100 }

        this.$refs.map.$mapObject.fitBounds(bounds, padding)

        mapData.addListener('click', (event) => {
          this.handlePolygonClick(event)
        })
      })
    })
  },
  methods: {
    handlePolygonClick (event) {
      const { latLng } = event
      if (this.selectionMode === 'origin') {
        this.$store.dispatch('drt/setOrigin', { latLng })
        this.$store.dispatch('drt/setSelectionMode', 'destination')
      } else {
        this.$store.dispatch('drt/setDestination', { latLng })
      }
    },
    handleMapClick () {
      this.$q.notify(this.$t('drt.validation.location'))
    },
    setBounds () {
      if (environment === 'test') return
      if (this.origin && this.destination) {
        const bounds = new this.google.maps.LatLngBounds()
        bounds.extend(this.origin)
        bounds.extend(this.destination)
        const padding = window.innerWidth > 768
          ? { bottom: 300, right: 300, top: 100 }
          : { top: 0, bottom: 0 }
        this.$refs.map.fitBounds(bounds, padding)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.route-map
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
  @media (max-width 768px)
    height 60vh
</style>
